import { Filter, List, RecordRepresentation } from 'react-admin'
import { useTranslate } from 'ra-core'
import { useTheme } from '@mui/styles'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UnitField from '../../components/UnitField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleLockField from '../../components/VehicleLockField'
import { getStatusStyles } from '../../domain/statuses'
import { isVehicleInstalled } from '../../domain/vehicles'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import useGetOrganisationWithHubFilters from '../common/filters'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'

import config from './config'
import vehiclesExporter from './exporter'
import { VehicleActiveFilter, VehicleLockFilter, VehicleTypeFilter } from './filter'
import VehicleFormLayout, { transformValues } from './form'

const VehiclesFilters = (props) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  return (
    <Filter {...props}>
      <AdvancedSearchFilter alwaysOn />
      {organisationWithHubFilters && organisationWithHubFilters.map((Filter) => Filter)}
      <VehicleTypeFilter alwaysOn />
      <VehicleActiveFilter alwaysOn />
      <VehicleLockFilter alwaysOn />
    </Filter>
  )
}

const vehicleRowSx = (record) => {
  const isInstalled = isVehicleInstalled(record)
  return { opacity: isInstalled ? 1 : 0.4 }
}

const VehiclesListLayout = () => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const theme = useTheme()
  const translate = useTranslate()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={vehicleRowSx}
          icon={<VehicleIconField />}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={<RecordRepresentation />}
          body={() => (
            <>
              <StaticText>{`${translate('resources.organisations.name', 1)}: `}</StaticText>
              <ReferenceValue reference="organisations" />
              <br />
              <StaticText>{`${translate('resources.hubs.name', 1)}: `}</StaticText>
              <ReferenceValue reference="hubs" />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid rowSx={vehicleRowSx}>
          <StatusField />
          {!hasSingleOrganisation && (
            <OrganisationField sortable={false} label={translate('resources.organisations.name', 1)} />
          )}
          {!hasSingleHub && <HubField sortable={false} />}
          <VehicleIconField color="textSecondary" />
          <AdvancedTextField source="brand" />
          <AdvancedTextField source="model" />
          <AdvancedTextField source="designation" />
          <UnitField source="autonomy" />
          <VehicleLockField />
          <AdvancedEditInDialogButton transform={transformValues}>
            <VehicleFormLayout />
          </AdvancedEditInDialogButton>
        </ListDatagrid>
      }
    />
  )
}

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filters={<VehiclesFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={vehiclesExporter}
      actions={
        <ListActions hasExport>
          <AdvancedCreateButton transform={transformValues}>
            <VehicleFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <VehiclesListLayout />
    </List>
  )
}
