import { Box } from '@mui/material'
import classnames from 'classnames'
import { get, isEqual } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { EmailField, ReferenceField, SimpleShowLayout, useGetIdentity, useGetOne, useRecordContext } from 'react-admin'

import { useApi } from '../../api/apiProvider'
import AdvancedArrayField from '../../components/AdvancedArrayField'
import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import FieldWrapper from '../../components/FieldWrapper'
import MobileAppVersionField from '../../components/MobileAppVersionField'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PriceField from '../../components/PriceField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UserDocumentStatusField from '../../components/UserDocumentStatusField'
import UserNFCCardsField from '../../components/UserNFCCardsField'
import UserPaymentMethodsField from '../../components/UserPaymentMethodsField'
import { viewModeList } from '../../components/ViewModeToggle'
import {
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
  ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE,
} from '../../config/accounts'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPES } from '../../config/bookings'
import { OPS_USER_ROLE_OWNER } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import {
  BILLING_TYPES_OVERRIDE_SOURCE,
  DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE,
  FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE,
  REQUIRES_PAYMENT_OVERRIDE_SOURCE,
  USER_ACCOUNTS_CLOSED_BY,
  USER_ACCOUNTS_CLOSING_REASONS,
  USER_ROLES,
} from '../../config/users'
import { hasBillingType, hasMobilityBudgetBillingType } from '../../utils'
import { useSmallScreen } from '../../utils/theme'
import { useGetCurrentAccount } from '../accounts/hooks'
import bookingsConfig from '../bookings/config'
import { BookingsListLayout } from '../bookings/list'
import { BookingShowLayout } from '../bookings/show'
import { ListReference } from '../common/list'
import {
  ShowActions,
  ShowCard,
  ShowReference,
  ShowReferenceLinks,
  ShowSubSectionTitleCard,
  useShowStyles,
} from '../common/show'
import paymentActionsConfig from '../paymentActions/config'
import PaymentActionsListLayout from '../paymentActions/list'
import userDetailsConfig from '../userDetails/config'
import { VehicleShowLayout } from '../vehicles/show'

const isBillingFieldOverriden = (record, source, account, accountSource) => {
  const value = get(record, source, null)
  const accountValue = get(account, accountSource, null)
  return value !== null && !isEqual(value, accountValue)
}

const UserShowLayout = () => {
  const account = useGetCurrentAccount()
  const record = useRecordContext()
  const { data: userDetails } = useGetOne(userDetailsConfig.name, { id: record.id })

  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => fetchMeInfo(), [fetchMeInfo])

  const translate = useTranslate()
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const getCommonAccountFieldProps = useMemo(
    () => (isFieldOverriden) => ({
      record: account,
      tooltip: translate('resources.users.show.accountSettings'),
      className: classnames(showClasses.italicHintText, isFieldOverriden ? showClasses.disabledText : null),
    }),
    [account?.id, translate], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const commonUserOverrideFieldProps = {
    className: showClasses.overrideText,
    tooltip: translate('resources.users.show.userSettings'),
  }

  if (!account || !userDetails || !meInfo || !identity) {
    return <ShowCardHeader />
  }

  const operationalStatusSectionProps = { data: userDetails.status_timeline }

  const accountHasBillingTypeFree = hasBillingType(account[ACCOUNT_BILLING_TYPES_SOURCE], BOOKING_BILLING_TYPE_FREE)
  const accountHasMobilityBudget = hasMobilityBudgetBillingType(account[ACCOUNT_BILLING_TYPES_SOURCE])
  const userHasBillingOverride = Boolean(record[BILLING_TYPES_OVERRIDE_SOURCE])
  const userHasBillingTypeFreeInOverride = hasBillingType(
    record[BILLING_TYPES_OVERRIDE_SOURCE],
    BOOKING_BILLING_TYPE_FREE,
  )
  const userHasMobilityBudgetInOverride = hasMobilityBudgetBillingType(record[BILLING_TYPES_OVERRIDE_SOURCE])
  const shouldDisplayMobilityBudgetSection =
    identity.role === OPS_USER_ROLE_OWNER &&
    ((!userHasBillingOverride && accountHasMobilityBudget) || userHasMobilityBudgetInOverride)

  return (
    <>
      <ShowCardHeader />
      <div className={isSmallScreen ? null : showClasses.row}>
        <div className={showClasses.expanded}>
          <ShowSubSectionTitleCard text="information" />
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField source="first_name" />
            <AdvancedTextField source="last_name" />
            <EmailField source="email" emptyText="n/a" />
            <AdvancedTextField source="phone_number" />
            <AdvancedTextField
              source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
              label="resources.users.fields.address"
            />
            <MobileAppVersionField mobileAppLatestVersion={meInfo.mobile_app_latest_version} />
            <DateTooltipField source="created_on" addTime />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField source="role" map={USER_ROLES} />
            <UserDocumentStatusField record={{ ...record, ...userDetails }} />
            <AdvancedTextField source="monitoring_email" />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <UserNFCCardsField />
          </SimpleShowLayout>
          {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
          {Boolean(record.company_legal_info) && (
            <>
              <ShowSubSectionTitleCard text="professionalAccount" />
              <SimpleShowLayout className={showClasses.fieldContainer}>
                <AdvancedTextField source="company_legal_info.name" />
                <AdvancedTextField source="company_legal_info.vat_number" />
                <AdvancedTextField
                  source="company_legal_info.address.formatted_address"
                  label="resources.users.fields.company_legal_info.address"
                />
              </SimpleShowLayout>
            </>
          )}
          <ShowSubSectionTitleCard text="paymentInformation" />
          <SimpleShowLayout record={userDetails} className={showClasses.fieldContainer}>
            <UserPaymentMethodsField />
            <PriceField source="balance" />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <FieldWrapper source={BILLING_TYPES_OVERRIDE_SOURCE} accountSource={ACCOUNT_BILLING_TYPES_SOURCE}>
              {({ source, accountSource }) => {
                const isOverriden = isBillingFieldOverriden(record, source, account, accountSource)
                const commonFieldProps = { source, map: BOOKING_BILLING_TYPES }
                return (
                  <>
                    <AdvancedArrayField
                      {...commonFieldProps}
                      {...getCommonAccountFieldProps(isOverriden)}
                      source={accountSource}
                    />
                    {'\xa0\xa0'}
                    {isOverriden && <AdvancedArrayField {...commonFieldProps} {...commonUserOverrideFieldProps} />}
                  </>
                )
              }}
            </FieldWrapper>
            <FieldWrapper
              source={DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE}
              accountSource={ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE}
            >
              {({ source, accountSource }) => {
                const isOverriden = isBillingFieldOverriden(record, source, account, accountSource)
                const commonFieldProps = { source, map: BOOKING_BILLING_TYPES }
                return (
                  <>
                    <AdvancedTextField
                      {...commonFieldProps}
                      {...getCommonAccountFieldProps(isOverriden)}
                      source={accountSource}
                    />
                    {'\xa0\xa0'}
                    {isOverriden && <AdvancedTextField {...commonFieldProps} {...commonUserOverrideFieldProps} />}
                  </>
                )
              }}
            </FieldWrapper>
            {(accountHasBillingTypeFree || userHasBillingTypeFreeInOverride) && (
              <FieldWrapper
                source={FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE}
                accountSource={ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE}
              >
                {({ source, accountSource }) => {
                  const isOverriden = isBillingFieldOverriden(record, source, account, accountSource)
                  const commonFieldProps = { source, trueIcon: null, falseIcon: null }
                  return (
                    <>
                      <AdvancedBooleanField
                        {...commonFieldProps}
                        {...getCommonAccountFieldProps(isOverriden)}
                        source={accountSource}
                        overrideLabel={accountHasBillingTypeFree ? null : 'n/a'}
                      />
                      {'\xa0\xa0'}
                      {isOverriden && (
                        <AdvancedBooleanField
                          {...commonFieldProps}
                          {...commonUserOverrideFieldProps}
                          overrideLabel={userHasBillingTypeFreeInOverride ? null : 'n/a'}
                        />
                      )}
                    </>
                  )
                }}
              </FieldWrapper>
            )}
            <FieldWrapper source={REQUIRES_PAYMENT_OVERRIDE_SOURCE} accountSource="requires_payment">
              {({ source, accountSource }) => {
                const isOverriden = isBillingFieldOverriden(record, source, account, accountSource)
                const commonFieldProps = { source, trueIcon: null, falseIcon: null }
                return (
                  <>
                    <AdvancedBooleanField
                      {...commonFieldProps}
                      {...getCommonAccountFieldProps(isOverriden)}
                      source={accountSource}
                    />
                    {'\xa0\xa0'}
                    {isOverriden && <AdvancedBooleanField {...commonFieldProps} {...commonUserOverrideFieldProps} />}
                  </>
                )
              }}
            </FieldWrapper>
          </SimpleShowLayout>
          {shouldDisplayMobilityBudgetSection && (
            <>
              <ShowSubSectionTitleCard text="mobilityBudgetIntegration" />
              <SimpleShowLayout className={showClasses.fieldContainer}>
                <AdvancedTextField source="mbrella_id" />
                <AdvancedTextField source="skipr_id" />
              </SimpleShowLayout>
            </>
          )}
          <ShowSubSectionTitleCard text="accountStatus" />
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <DateTooltipField source="user_account.closed_on" addTime />
            <AdvancedTextField source="user_account.closing_reason" map={USER_ACCOUNTS_CLOSING_REASONS} />
            <AdvancedTextField source="user_account.closed_by" map={USER_ACCOUNTS_CLOSED_BY} />
            <AdvancedTextField source="user_account.closing_justification" multiline />
          </SimpleShowLayout>
          <ShowActions allowedActions={userDetails.allowed_actions} />
        </div>
        {!isSmallScreen && (
          <Box width="100%" maxWidth={400} className={commonClasses.borderLeft}>
            <OperationalStatusSection {...operationalStatusSectionProps} />
          </Box>
        )}
      </div>
      <ShowReferenceLinks />
    </>
  )
}

const UserAside = () => {
  const record = useRecordContext()
  const { data: userDetails } = useGetOne(userDetailsConfig.name, { id: record.id })

  const [viewMode, setViewMode] = useState(viewModeList)

  return (
    <>
      <ReferenceField record={userDetails} reference="bookings" source="current_booking_id" link={false}>
        <ShowCard>
          <BookingShowLayout
            excluded={['users', 'vehicles']}
            title="resources.common.show.currentBooking"
            hasRedirect
          />
        </ShowCard>
        <ShowReference reference="vehicles" source="vehicle_id">
          <VehicleShowLayout excluded={['organisations']} hasRedirect title="resources.users.show.currentVehicle" />
        </ShowReference>
      </ReferenceField>

      <ListReference
        reference="bookings"
        target="user_id"
        sort={bookingsConfig.options.defaultSort}
        filter={bookingsConfig.options.defaultFilterValues}
        showPagination={viewMode === viewModeList}
      >
        <BookingsListLayout
          excluded={['users']}
          disabledInputsSources={['user_id']}
          viewMode={viewMode}
          setViewMode={setViewMode}
          schedulerProps={{
            creationInitialValues: { user_id: record.id },
            filter: { user_id: record.id },
            groupAccessoryNameKey: null,
            groupCategories: null,
            groupFilter: { id: [record.id] },
            groupNameKey: null,
            groupResource: 'users',
            relatedResource: 'vehicles',
            vehicleUnavailabilityCreationDisabled: true,
          }}
        />
      </ListReference>
      <ListReference reference="payment-actions" target="user_id" sort={paymentActionsConfig.options.defaultSort}>
        <PaymentActionsListLayout />
      </ListReference>
    </>
  )
}

export default () => (
  <AdvancedShow aside={<UserAside />}>
    <UserShowLayout />
  </AdvancedShow>
)
