import { Card, CardHeader } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { cloneElement, useMemo } from 'react'
import {
  Datagrid,
  ExportButton,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  useListContext,
  useResourceContext,
} from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { useCommonStyles } from '../../config/theme'
import { useSmallScreen } from '../../utils/theme'

import { CardTitle } from './index'

export const useListStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}))

export const ListReference = ({ children, classes: classesOverride = {}, showPagination = true, ...props }) => {
  const classes = useListStyles({ classes: classesOverride })
  const [hasRead] = useResourcePermissions(props.reference, SYSTEM_PERMISSION_READ)
  return hasRead ? (
    <Card className={classes.card}>
      <ReferenceManyField {...props} perPage={10} pagination={showPagination ? <Pagination /> : null}>
        {cloneElement(children, { isChildOfListReference: true })}
      </ReferenceManyField>
    </Card>
  ) : null
}

export const ListActions = ({ hasExport = false, children }) => {
  const { resource, sort, filterValues, displayedFilters, selectedIds, total } = useListContext()
  return useMemo(
    () => (
      <TopToolbar>
        {children}
        {hasExport && (
          <ExportButton resource={`${resource}/export`} sort={sort} filterValues={filterValues} maxResults={10000} />
        )}
      </TopToolbar>
    ),
    [resource, sort, displayedFilters, filterValues, selectedIds, total, children], // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export const ListCardTitle = () => {
  const resource = useResourceContext()
  return <CardTitle text={`resources.${resource}.name`} args={2} />
}

export const ListDatagrid = ({ children, expand, isChildOfListReference, rowClick = 'show', ...props }) => {
  const commonClasses = useCommonStyles()
  return (
    <Datagrid
      {...props}
      className={isChildOfListReference ? null : commonClasses.datagridWithoutLastBottomBorder}
      expand={expand}
      rowClick={expand ? 'expand' : rowClick}
    >
      {children}
    </Datagrid>
  )
}

export const GenericListLayout = ({
  action = null,
  compactListLayout,
  editPopupDialog = null,
  isChildOfListReference = false,
  regularListLayout,
  title = <ListCardTitle />,
}) => {
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const renderMainContent = (listLayout) => (
    <>
      <CardHeader
        title={title}
        className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}
        sx={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        action={action}
      />
      {cloneElement(listLayout, { isChildOfListReference })}
      {editPopupDialog}
    </>
  )

  if (isSmallScreen && !isChildOfListReference) {
    return <Card>{renderMainContent(compactListLayout)}</Card>
  }
  return renderMainContent(isSmallScreen ? compactListLayout : regularListLayout)
}
