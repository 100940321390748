import { createBrowserHistory as createHistory } from 'history'
import createSimpleRestProvider from 'ra-data-simple-rest'
import * as React from 'react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Admin, CustomRoutes, localStorageStore, Resource } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClient } from 'react-query'
import * as Sentry from '@sentry/react'

import createAccountsProvider, { AccountsProvider } from './api/accountsProvider'
import createApiClient from './api/apiClient'
import createApiProvider, { ApiProvider } from './api/apiProvider'
import createAuthProvider from './api/authProvider'
import createDataProvider from './api/dataProvider'
import firebaseClient from './api/firebaseClient'
import createLocalApiClient from './api/localApiClient'
import createLocalAuthProvider from './api/localAuthProvider'
import createPermissionsProvider from './api/permissionsProvider'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import lightTheme, { darkTheme } from './config/theme'
import env from './config/env'
import i18nProvider from './config/i18nProvider'
import './config/overrides'
import {
  SYSTEM_PERMISSION_CREATE,
  SYSTEM_PERMISSION_READ,
  SYSTEM_PERMISSION_UPDATE,
  UI_PERMISSION_MENU,
} from './config/permissions'
import { hasResourcePermission } from './domain/permissions'
import { list as resources } from './resources'
import { AccountEditPage } from './resources/accounts/edit'
import { AccountShowPage } from './resources/accounts/show'
import { LoginPage, RedirectPage, ResetPage } from './resources/common/login'
import { PayoutShowScreen } from './resources/payout/screens/show'

import './vendor'

Sentry.init({ dsn: env.SENTRY_DSN, integrations: [], environment: env.ENV_NAME })

const baseTitle = document.title
export const useDocumentTitle = (funcOrString, displayBaseTitle = false) =>
  useLayoutEffect(() => {
    const documentTitle = typeof funcOrString === 'function' ? funcOrString() : funcOrString
    document.title = (documentTitle ?? '') + (displayBaseTitle ? ' - ' + baseTitle : '')
  })

const extractSlugFromPath = (path) => path.split('/')?.[1]

const getResourceWithPermissions = (resource, permissions) => {
  const resourceName = resource.name.split('/')[0]
  const hasPermission = (permission) => hasResourcePermission(permissions, resourceName, permission)
  if (!hasPermission(SYSTEM_PERMISSION_READ)) {
    return null
  }
  const { list, show, create, edit, ...rest } = resource
  const props = {
    ...rest,
    list: (hasPermission(SYSTEM_PERMISSION_READ) ? list : null) || null,
    show: (hasPermission(SYSTEM_PERMISSION_READ) ? show : null) || null,
    create: (hasPermission(SYSTEM_PERMISSION_CREATE) ? create : null) || null,
    edit: (hasPermission(SYSTEM_PERMISSION_UPDATE) ? edit : null) || null,
  }
  return <Resource {...props} />
}

const initialPath = window.location.pathname
const store = localStorageStore()
store.setItem('sidebar.open', false)

const customRoutes = [
  { element: ResetPage, noLayout: true },
  { element: RedirectPage, noLayout: true },
  { element: AccountShowPage },
  { element: AccountEditPage },
  { element: PayoutShowScreen },
]

const App = () => {
  const [state, setState] = useState(null)

  useEffect(() => {
    async function start() {
      const isLocalDev = !!env.LOCAL_TOKEN_OVERRIDE
      const baseApiClient = isLocalDev ? createLocalApiClient() : createApiClient(firebaseClient)
      const accountsProvider = createAccountsProvider(env.API_URL, baseApiClient)
      const apiClient = accountsProvider.client

      const restDataProvider = createSimpleRestProvider(env.API_URL, apiClient)
      const apiProvider = createApiProvider(env.API_URL, apiClient)
      const dataProvider = createDataProvider(restDataProvider, apiProvider)

      const history = createHistory()
      let basename
      let currentAccount = await accountsProvider.getCurrentAccount()
      if (currentAccount) {
        const initialSlug = extractSlugFromPath(initialPath)
        if (initialSlug !== currentAccount.slug) {
          // slug is different from current account (could be empty on first run)
          const slugAccount = accountsProvider.findAccountBySlug(initialSlug)
          if (slugAccount) {
            // we found a valid account with the specified slug --> set current account as the newly found account
            resources.forEach((resource) => {
              const key = `${resource.name}.listParams`
              store.removeItem(key)
            })
            accountsProvider.setCurrentAccount(slugAccount)
            currentAccount = slugAccount
          } else {
            // no account with specified slug, use slug from current account
            if (initialSlug !== '' && initialSlug !== 'login' && initialSlug !== 'reset-password') {
              // a slug was present & different than base login URLs, but invalid
              window.alert("You don't have access to this account")
            }
            accountsProvider.navigateToCurrentAccount()
            return
          }
        }
        basename = '/' + currentAccount.slug
      } else {
        basename = undefined
      }

      const permissionsProvider = createPermissionsProvider(apiProvider)
      const authProvider = isLocalDev
        ? createLocalAuthProvider(accountsProvider, permissionsProvider)
        : createAuthProvider(firebaseClient, accountsProvider, baseApiClient, permissionsProvider, history, [
            ResetPage.path,
          ])

      // Find the first resource with "MENU" permission (if connected) and redirect to it if we are on the root path
      if (currentAccount) {
        const permissions = await permissionsProvider.get()
        const firstResource = resources.find((r) => hasResourcePermission(permissions, r.name, UI_PERMISSION_MENU))
        const pathRegex = new RegExp(`^/?${currentAccount.slug}/?$`)
        const isRootPath = pathRegex.test(history.location.pathname)
        if (firstResource && isRootPath) {
          history.push(basename + '/' + firstResource.name)
        }
      }

      setState({ basename, history, apiProvider, accountsProvider, authProvider, dataProvider, i18nProvider })
    }
    start()
  }, [setState])

  if (!state) {
    return null
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30 * 1000, // 30 seconds
        retry: 0,
      },
    },
  })

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ApiProvider value={state.apiProvider}>
        <AccountsProvider value={state.accountsProvider}>
          <BrowserRouter basename={state.basename}>
            <Admin
              requireAuth={true}
              authProvider={state.authProvider}
              catchAll={NotFound}
              dataProvider={state.dataProvider}
              disableTelemetry
              queryClient={queryClient}
              theme={lightTheme}
              darkTheme={darkTheme}
              i18nProvider={state.i18nProvider}
              layout={Layout}
              loginPage={LoginPage}
              store={store}
            >
              {customRoutes.map((route, index) => (
                <CustomRoutes key={index} noLayout={Boolean(route.noLayout)}>
                  <Route path={route.element.path} element={<route.element />} />
                </CustomRoutes>
              ))}
              {(permissions) => {
                console.log('permissions', permissions)
                return resources.map((r) => getResourceWithPermissions(r, permissions))
              }}
            </Admin>
          </BrowserRouter>
        </AccountsProvider>
      </ApiProvider>
    </LocalizationProvider>
  )
}

export default App
