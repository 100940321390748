import { grey, orange, teal } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import { defaultTheme } from 'react-admin'

export const COMMON_INPUT_WIDTH = 256

const base = {
  ...defaultTheme,
  palette: {
    text: { hint: 'rgba(255, 255, 255, 0.5)' },
    success: { main: '#2CA428', faded: '#4FD34A' },
    warning: orange,
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 16,
          marginBottom: 0,
          width: COMMON_INPUT_WIDTH + 'px',
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '18px 0 10px 0',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          margin: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          height: 40,
        },
        action: {
          alignSelf: 'center',
          marginTop: '0px !important',
          marginRight: '0px !important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiLinearProgress-root': {
            // Prevent loading bars in ReferenceFields from making containing table cells grow
            width: '100%',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // Make icons in menu items inherit their parent color so they're highlighted when active
          color: 'inherit',
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          // Don't display timeline left sections
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          h6: {
            fontWeight: 500,
          },
          '.MuiDialog-paper': {
            flex: 1,
            maxWidth: '640px',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 16,
          fontWeight: 500,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingBottom: 12,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          '& .MuiDialogActions-root': {
            marginTop: -10,
            paddingTop: 0,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: '1 !important',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-row': { height: 43 },
          '& .RaDatagrid-headerCell': { backgroundColor: 'inherit' },
        },
      },
    },
    RaCheckboxGroupInput: {
      styleOverrides: {
        root: {
          marginLeft: 32,
          '& .MuiFormGroup-root > .MuiFormControlLabel-root': {
            height: 40,
          },
        },
      },
    },
    RaFormDialogTitle: {
      styleOverrides: {
        root: {
          '& .RaFormDialogTitle-closeButton': {
            marginTop: 4,
            marginRight: 4,
          },
        },
      },
    },
    RaImageInput: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          '& .RaFileInput-dropZone': {
            marginTop: 4,
          },
        },
      },
    },
    RaLink: {
      styleOverrides: {
        link: {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    RaShow: {
      styleOverrides: {
        root: {
          '& .RaShow-main': {
            flexDirection: 'column',
          },
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
          '& > a': {
            textDecoration: 'none !important',
          },
        },
      },
    },
    RaArrayInput: {
      styleOverrides: {
        root: {
          margin: 16,
          marginTop: 10,
        },
      },
    },
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          display: 'contents',
        },
      },
    },
    RaConfirm: {
      styleOverrides: {
        root: {
          '& .RaConfirm-confirmWarning': {
            border: '1px solid',
            padding: '5px 15px',
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
    },
    RaFilterFormInput: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          marginTop: 8,
          '& .MuiFormControl-root': {
            width: 227,
            margin: 0,
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '& .RaLayout-content': {
            padding: '5px 10px 8px 5px',
            marginLeft: '5px',
          },
        },
      },
    },
    RaSimpleForm: {
      styleOverrides: {
        root: {
          padding: '10px 0 16px 0',
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          minWidth: COMMON_INPUT_WIDTH,
          '& .RaSimpleFormIterator-line': {
            marginTop: 16,
            marginBottom: 8,
            '&:first-child': {
              marginTop: 8,
            },
          },
          '& .RaSimpleFormIterator-inline': {
            columnGap: 32,
          },
          '& .MuiFormControl-root': {
            margin: 0,
          },
          '& .RaSimpleFormIterator-action': {
            marginLeft: 16,
          },
        },
      },
    },
    RaRadioButtonGroupInput: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 8,
          marginLeft: 16,
          marginRight: 16,
          '& .RaRadioButtonGroupInput-label': {
            marginBottom: 6,
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          '& .RaSimpleShowLayout-stack': {
            display: 'flex !important',
            flexDirection: 'row !important',
            flexWrap: 'wrap !important',
            gap: '16px !important',
            alignItems: 'start',
          },
          '& .RaSimpleShowLayout-row': {
            margin: 16,
            marginBottom: 0,
          },
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          '& > .MuiDivider-root': { display: 'none' },
          '& .RaTabbedShowLayout-content': { padding: 0 },
          '& .RaTab-row > .RaLabeled-label': { display: 'none' },
        },
      },
    },
  },
}

export const THEME_MODE_LIGHT = 'light'
export const lightTheme = {
  ...base,
  palette: {
    ...base.palette,
    mode: THEME_MODE_LIGHT,
    primary: teal,
    secondary: { main: '#25333f' },
    error: { main: '#F44336', faded: '#F76E64' },
    contrast: grey[600],
  },
}

export const THEME_MODE_DARK = 'dark'
export const darkTheme = {
  ...base,
  palette: {
    ...base.palette,
    mode: THEME_MODE_DARK,
    background: { default: '#303030', paper: '#303030' },
    contrast: 'white',
    primary: { main: 'hsl(174, 80%, 45%)', contrastText: '#000' },
    secondary: { main: 'hsl(174, 50%, 45%)', contrastText: '#000' },
    error: { main: '#F44336', faded: '#F76E64' },
  },
}

export const useCommonStyles = makeStyles((theme) => {
  const { breakpoints, palette, spacing } = theme
  const isLightMode = palette.mode === THEME_MODE_LIGHT

  return {
    inheritedFont: {
      fontSize: 'inherit !important',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
    textWithLargeIcons: {
      whiteSpace: 'nowrap',
      '& svg': {
        verticalAlign: 'middle',
        margin: '0 0.3rem',
      },
    },
    textWithSmallIcons: {
      whiteSpace: 'nowrap',
      '& svg': {
        verticalAlign: 'middle',
        fontSize: '1rem',
        padding: '0 0.2rem',
      },
    },
    titleContainer: {
      [breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 40,
      },
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    solidBorder: {
      borderStyle: 'solid',
    },
    dashedBorder: {
      borderStyle: 'dashed',
    },
    contrastColor: {
      color: palette.contrast,
    },
    borderTop: {
      borderTop: `solid 1px ${palette.divider}`,
    },
    borderLeft: {
      borderLeft: `solid 1px ${palette.divider}`,
    },
    borderBottom: {
      borderBottom: `solid 1px ${palette.divider}`,
    },
    borderRight: {
      borderRight: `solid 1px ${palette.divider}`,
    },
    borderless: {
      border: 'none !important',
    },
    fullWidth: {
      width: '100% !important',
    },
    halfWidth: {
      width: '50% !important',
    },
    inline: {
      display: 'inline',
    },
    absoluteFill: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    objectContain: {
      objectFit: 'contain',
    },
    flexRowCenterContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    spacer: {
      flexGrow: 1,
    },
    toolbar: {
      '& > button + button': {
        marginLeft: spacing(1),
      },
    },
    whiteLogo: {
      padding: spacing(0.5),
      margin: -spacing(0.5),
      backgroundColor: 'white',
    },
    primaryColor: {
      color: palette.primary.main,
    },
    imageInput: {
      '& .RaFileInput-dropZone': {
        backgroundColor: isLightMode ? palette.grey[200] : palette.grey[800],
      },
    },
    link: {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    commonInput: {
      width: COMMON_INPUT_WIDTH + 'px !important',
    },
    doubleInput: {
      width: COMMON_INPUT_WIDTH * 2 + 32 + 'px !important',
    },
    smallDot: {
      marginRight: spacing(1),
      borderRadius: 5,
      height: 10,
      width: 10,
    },
    commonSwitchRoot: {
      minWidth: 100,
      height: 36,
      color: 'inherit',
      fontSize: 18,
      fontWeight: 500,
      border: '2px solid',
      borderRadius: 6,
      borderColor: isLightMode ? palette.grey[500] : 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        borderColor: isLightMode ? palette.grey[300] : 'rgba(0, 0, 0, 0.87)',
        transition: 'border-color 0.5s ease',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // Remove background border (white when not focused & green when focused)
        borderStyle: 'none',
      },
    },
    commonSwitchIcon: {
      color: 'inherit',
      position: 'absolute',
      right: 3,
    },
    commonSwitchExpandedBorder: {
      borderColor: isLightMode ? palette.primary.main : 'rgba(25, 230, 209, 0.8)',
    },
    datagridWithoutLastBottomBorder: {
      '& .RaDatagrid-row:last-child': {
        '& .RaDatagrid-rowCell': {
          borderBottom: 'none',
        },
        '& .RaDatagrid-expandIconCell': {
          borderBottom: 'none',
        },
      },
    },
  }
})

export const THEME_LINEAR_GRADIENT_GREEN = 'green'
export const THEME_LINEAR_GRADIENT_YELLOW = 'yellow'
export const THEME_LINEAR_GRADIENT_ORANGE = 'orange'
export const THEME_LINEAR_GRADIENT_RED = 'red'

export const useLinearGradientCommonStyles = makeStyles((theme) => ({
  [THEME_LINEAR_GRADIENT_GREEN]: {
    backgroundImage: `linear-gradient(${theme.palette.success.faded},${theme.palette.success.main})`,
  },
  [THEME_LINEAR_GRADIENT_YELLOW]: {
    backgroundImage: `linear-gradient(${theme.palette.warning[300]},${theme.palette.warning[400]})`,
  },
  [THEME_LINEAR_GRADIENT_ORANGE]: {
    backgroundImage: `linear-gradient(${theme.palette.warning[500]},${theme.palette.warning[700]})`,
  },
  [THEME_LINEAR_GRADIENT_RED]: {
    backgroundImage: `linear-gradient(${theme.palette.error.faded},${theme.palette.error.main})`,
  },
}))

export default lightTheme
