import React from 'react'
import { Show } from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { useResourcePermissions } from '../domain/permissions'
import { ListReference } from '../resources/common/list'
import { Title } from '../resources/common/show'
import eventsConfig from '../resources/events/config'
import { EventsListLayout } from '../resources/events/list'

const AdvancedShow = ({ children, aside, ...props }) => {
  const [hasReadForEvent] = useResourcePermissions(eventsConfig.name, SYSTEM_PERMISSION_READ)
  return (
    <Show
      title={<Title />}
      aside={
        <>
          {aside}
          {hasReadForEvent && (
            <ListReference reference={eventsConfig.name} target="entity_id" sort={eventsConfig.options.defaultSort}>
              <EventsListLayout excludedFields={['entity_id']} />
            </ListReference>
          )}
        </>
      }
      emptyWhileLoading
      {...props}
    >
      {children}
    </Show>
  )
}

export default AdvancedShow
